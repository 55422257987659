<template>
  <div>
    <!-- BASIC INFORMATION -->
    <v-form ref="form">
      <v-card class="mt-1">
        <v-card-title>Perustiedot</v-card-title>

        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                dense
                v-model="settings.public.showPublic"
                label="Näytä vuokrakohde julkisesti"
                outlined
                hide-details
              ></v-checkbox>
              <small
                >Jos tilin asetuksissa on valittuna 'näytä kaikki', valinta ei vaikuta vuokrakohteen
                näkyvyyteen.</small
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-btn color="info" class="mr-4 mt-5" @click="saveSettings">{{ saveBtnText }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";

export default {
  props: ["save-btn-text"],

  components: {},

  mixins: [mixins],

  data() {
    return {
      settings: {
        public: {
          showPublic: false,
        },
      },
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment"]),
  },

  watch: {
    currentApartment(val) {
      this.settings = val.settings;
    },
  },

  methods: {
    ...mapActions("apartment", ["updateApartmentPublicSettings"]),

    saveSettings() {
      if (this.$refs.form.validate()) {
        this.updateApartmentPublicSettings({
          settings: this.settings,
          apartmentId: this.$route.params.id,
        });
      }
    },

    testNumber(num) {
      return !num ? true : num > 0 ? true : false;
    },
  },
};
</script>

<style scoped></style>
