<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa vuokrakohdetta</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>
    <p v-if="!loading" style="font-size: 18px">
      {{
        currentApartment.apartmentNumber
          ? `${currentApartment.address}, ${currentApartment.apartmentNumber}`
          : currentApartment.address
      }}
    </p>

    <v-row v-if="!loading" dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="tab">
          <v-btn value="0"> Perustiedot </v-btn>
          <v-btn v-if="isAuthorized('account', 'publicSettings')" value="1">
            Julkiset asetukset
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!--  Basic form -->
    <apartment-form v-show="tab == '0'" save-btn-text="Tallenna vuokrakohde"></apartment-form>
    <!--  Public form -->
    <apartment-public-settings
      v-show="tab == '1'"
      save-btn-text="Tallenna vuokrakohde"
    ></apartment-public-settings>
  </div>
</template>

<script>
import ApartmentForm from "../../components/Apartment/ApartmentForm";
import ApartmentPublicSettings from "../../components/Apartment/ApartmentPublicSettings";
import { mapActions, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";
import { mapState } from "vuex";

export default {
  title: "Muokkaa vuokrakohdetta",

  mixins: [mixins],

  components: {
    ApartmentForm,
    ApartmentPublicSettings,
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment", "loading"]),
  },

  async created() {
    try {
      this.setLoading(true);
      await Promise.all([
        this.getAllCondominiums({ url: "/api/v1/condominium/getAll", data: {} }),
        this.getOneApartment(this.$route.params.id),
      ]);
      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapActions("condominium", ["getAllCondominiums"]),
    ...mapActions("apartment", ["getOneApartment"]),
    ...mapMutations("apartment", ["setLoading"]),
  },
};
</script>

<style scoped></style>
